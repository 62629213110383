.Testimonials{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.testimonials-grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: flex-start;
    width: 80%;
    gap: 40px;
    margin: 60px 0px;
}

.testo-grid{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    background-color: rgba(236, 217, 77, 0.192);
    border-radius: 20px;
}

.testo-grid:nth-child(2){
    background-color: rgba(0, 118, 45, 0.11);
}
.testo-grid:nth-child(3){
    background-color: rgba(0, 0, 255, 0.205);
}
.testo-grid:nth-child(4){
    background-color: rgba(255, 0, 0, 0.2);
}
.testo-grid:nth-child(5){
    background-color: rgba(153, 205, 50, 0.206);
}
.testo-grid:last-child{
    background-color: rgba(255, 21, 60, 0.149);
}

.testo-img-cont{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.testo-img{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

.testo-cont{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

.testo-cont span{
    font-size: 40px;
    font-weight: 500;
}

.testo-cont p{
    font-size: 20px;
}

.testo-line{
    height: 2px;
    width: 60%;
    background-color: rgba(128, 128, 128, 0.419);
    margin: 20px 0px;
    border-radius: 10px;
}

.testo-content p{
    font-size: 20px;
    text-align: justify;
}

@media screen and (max-width: 1200px) {
    .testimonials-grid{
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 600px) {
    .testo-img-cont{
        flex-direction: column;
    }
    .testo-img{
        align-items: center;
    }
    .testo-cont{
        align-items: center;
    }
}

@media screen and (max-width: 420px) {
    .testo-img img{
        height: 100px;
    }
    .testo-cont span{
        font-size: 30px;
    }
    .testo-content p{
        font-size: 17px;
        text-align: left;
    }
}