@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

:root {
  --main-color: #0e5094;
  --grey-color: #edf3f5;
  --green-color: #00cc99;
  --font-color: #feffff;
  --black-color: #17252a;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  /* background-color: var(--grey-color); */
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a{
  text-decoration: none;
}

button{
  padding: 10px 30px;
  height: 50px;
  outline: none;
  border: none;
  background-color: var(--main-color);
  color: var(--font-color);
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
}

button:hover{
  background-color: var(--green-color);
}