.Home {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: url(../../Assets/back.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 800px;
}

.header-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 70%;
  gap: 30px;
}

.header-content span {
  font-size: 80px;
  font-weight: 600;
}

.header-content p {
  font-size: 30px;
}

.header-content p1{
  font-size: 20px;
  font-weight: 500;
}

.transparent{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.points{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: var(--main-color);
  height: 120px;
  width: 400px;
  color: var(--font-color);
  border: 2px solid var(--font-color);
  border-radius: 20px;
  
}

.transparent span1 {
  font-size: 24px;
  text-align: center;
}

.header-btns a:first-child {
  padding: 10px 30px;
  /* height: 70px; */
  outline: none;
  border: none;
  margin: 0px 30px 0px 0px;
  background-color: var(--main-color);
  color: var(--font-color);
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
}

.header-btns a:last-child {
  padding: 10px 30px;
  /* height: 70px; */
  outline: none;
  border: none;
  background-color: var(--font-color);
  color: var(--black-color);
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
}

.header-btns a:first-child:hover{
  background-color: var(--green-color);
  color: var(--font-color);
}

.header-btns a:last-child:hover{
  background-color: var(--main-color);
  color: var(--font-color);
}

.visatypes {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 800px;
  margin: 50px 0px;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 30px 0px;
  background: url(../../Assets/back3.png);
}

.visa-back{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 80%;
}

.visa-heading span {
  font-size: 50px;
  font-weight: 600;
}

.grid-type {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 20px 0px 0px 0px;
}

.type {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  height: 250px;
  width: 500px;
  background-color: var(--font-color);
  padding: 20px;
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.type img {
  height: 80px;
}

.type span {
  font-size: 24px;
  font-weight: 500;
}

.type p {
  font-size: 18px;
  font-weight: 400;
}

.guidance {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 700px;
  background: url(../../Assets/back2.png);
}

.guidance-main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 70%;
  gap: 30px;
}

.guidance-heading span {
  font-size: 50px;
  font-weight: 600;
  margin: 0px 0px 20px 0px;
}

.guidance-heading p {
  font-size: 22px;
}

.guidance-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 20px 0px 0px 0px;
}

.process {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 150px;
  width: 500px;
  background-color: var(--font-color);
  padding: 20px;
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.process-img {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 20%;
}

.process-img img {
  height: 50px;
}

.process-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 80%;
}

.process-content span {
  font-size: 24px;
  margin: 0px 0px 10px 0px;
}

.success {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 50px 0px;
  padding: 40px;
  height: 800px;
  width: 100%;
  background: url(../../Assets/back1.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.success-heading span {
  font-size: 50px;
  font-weight: 600;
  margin: 0px 0px 20px 0px;
}

.success-heading p {
  font-size: 24px;
}

.success-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 20px 0px 0px 0px;
  place-items: center;
}

.grid-process {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 250px;
  width: 500px;
  gap: 30px;
  background-color: var(--font-color);
  padding: 20px;
  border-radius: 20px 0px 0px 0px;
}

.grid-process1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 250px;
  width: 500px;
  gap: 30px;
  background-color: var(--font-color);
  padding: 20px;
  border-radius: 0px 20px 0px 0px;
}

.grid-process2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 250px;
  width: 500px;
  gap: 30px;
  background-color: var(--font-color);
  padding: 20px;
  border-radius: 0px 0px 0px 20px;
}

.grid-process3 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 250px;
  width: 500px;
  gap: 30px;
  background-color: var(--font-color);
  padding: 20px;
  border-radius: 0px 0px 20px 0px;
}

.grid-process:hover,
.grid-process1:hover,
.grid-process2:hover,
.grid-process3:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.grid-img,
.success-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.grid-img img {
  height: 120px;
}

.success-content span {
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 100px;
}

.home-faq {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 1700px) {
  .grid-type {
    grid-template-columns: repeat(2, 1fr);
  }
}


@media screen and (max-width: 1350px) {
  .header-content span{
    font-size: 60px;
  }
  .transparent span1{
    font-size: 20px;
  }
}

@media screen and (max-width: 1250px) {
  .type{
    width: 400px;
  }
  .process{
    width: 400px;
  }
}

@media screen and (max-width: 1125px) {
  .transparent span1{
    font-size: 16px;
  }
}

@media screen and (max-width: 1050px) {
  .grid-process{
    width: 400px;
    height: 200px;
  }
  .grid-process1{
    width: 400px;
    height: 200px;
  }
  .grid-process2{
    width: 400px;
    height: 200px;
  }
  .grid-process3{
    width: 400px;
    height: 200px;
  }
  .success-content span{
    font-size: 70px;
  }
  .header-content span{
    font-size: 50px;
  }
  .success-heading span{
    font-size: 40px;
  }
}

@media screen and (max-width: 1000px) {
  .type{
    width: 300px;
  }
  .type img{
    height: 60px;
  }
  .type span{
    font-size: 20px;
  }
  .type p{
    font-size: 16px;
  }
  .process{
    width: 300px;
  }
  .process-img img{
    height: 30px;
  }
  .process-content span{
    font-size: 18px;
  }
  .points{
    width: 300px;
  }
}

@media screen and (max-width: 850px) {
  .grid-process{
    width: 300px;
    height: 150px;
  }
  .grid-process1{
    width: 300px;
    height: 150px;
  }
  .grid-process2{
    width: 300px;
    height: 150px;
  }
  .grid-process3{
    width: 300px;
    height: 150px;
  }
  .success-content span{
    font-size: 40px;
  }
  .grid-img img {
    height: 100px;
  }
}

@media screen and (max-width: 850px) {
  .visatypes{
    height: 1200px;
  }
  .grid-type{
    grid-template-columns: repeat(1, 1fr);
  }
  .type{
    height: 200px;
    width: 100%;
  }
  .visa-heading span{
    font-size: 40px;
  }

  .guidance{
    height: 1000px;
  }
  .guidance-grid{
    grid-template-columns: 1fr;
  }
  .process{
    height: 150px;
    width: 100%;
  }
  .process-img img {
    height: 50px;
  }
  .success{
    height: 950px;
  }
  .success-grid{
    grid-template-columns: 1fr;
    width: 100%;
  }
  .grid-process{
    width: 100%;
    height: 150px;
    border-radius: 20px 20px 0px 0px;
  }
  .grid-process1{
    width: 100%;
    height: 150px;
    border-radius: 0px 0px 0px 0px;
  }
  .grid-process2{
    width: 100%;
    height: 150px;
    border-radius: 0px 0px 0px 0px;
  }
  .grid-process3{
    width: 100%;
    height: 150px;
    border-radius: 0px 0px 20px 20px;
  }
}

@media screen and (max-width: 850px) {
  .guidance-heading span{
    font-size: 40px;
  }
  .success-heading span{
    font-size: 40px;
  }
  .header-content span{
    font-size: 50px;
  }
  .header-content p{
    font-size: 24px;
  }
}

@media screen and (max-width: 760px) {
  .points{
    width: 100%;
  }
}

@media screen and (max-width: 610px) {
  .header-btns{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .header-btns a{
    margin: 10px 0px;
  }
  .transparent span1 {
    font-size: 14px;
  }
  .header-content p1{
    font-size: 14px;
  }
  .header-content{
    width: 90%;
  }
}

@media screen and (max-width: 500px) {
  .header-content{
    width: 90%;
  }
  .header-content span {
    font-size: 40px;
  }
  .guidance-main{
    width: 90%;
  }
  .success-heading span{
    font-size: 30px;
  }
  .success-heading p {
    font-size: 20px;
  }
}

@media screen and (max-width: 375px) {
  .success{
    height: 1100px;
  }
  .guidance{
    height: 1100px;
  }
  .header{
    height: 900px;
  }
}