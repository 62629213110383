.Footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(
    to bottom,
    var(--font-color) 0%,
    var(--font-color) 10%,
    var(--grey-color) 10%,
    var(--grey-color) 100%
  );
}

.footer-newsletter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--font-color);
    border-radius: 20px;
    padding: 0;
    width: 70%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.newsletter{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    padding: 0px 0px 0px 50px;
}

.newsletter p{
    font-size: 20px;
}

.newsletter span{
    font-size: 35px;
    margin: 20px 0px;
    font-weight: 400;
}

.formletter{
    display: flex;
    width: 100%;
}

.newsletter input{
    background-color: var(--grey-color);
    border: none;
    padding: 10px;
    border-radius: 5px;
    width: 50%;
    height: 50px;
    margin: 0px 10px 0px 0px;
}

.footer-form{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    background-color: var(--font-color);
    margin: 20px 0px;
    width: 70%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.contact-form{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    flex: 1;
}

.heading-form{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 0px 0px 30px 0px;
}

.heading-form p{
    font-size: 24px;
}

.heading-form span{
    font-size: 30px;
    font-weight: 500;
}

form{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

.too-inputs{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.too-inputs input{
    background-color: var(--font-color);
    border: 1px solid rgba(128, 128, 128, 0.300);
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    height: 50px;
    margin: 0px 10px 10px 0px;
}

.text-area{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.text-area textarea{
    background-color: var(--font-color);
    border: 1px solid rgba(128, 128, 128, 0.300);
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    height: 150px;
    margin: 0px 0px 10px 0px;
}

.contact-map{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    width: 100%;
}

.map{
    width: 100%;
    border-radius: 0px 20px 20px 0px;
    border: none;

}

.footer-links{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 70%;
    place-items: center;
}

.footer-info{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.footer-info span{
    font-size: 29px;
    margin: 0px 0px 10px 0px;
}

.logo-info{
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-info a{
    color: var(--black-color);
    font-size: 22px;
    margin: 0px 0px 10px 0px;
}

.f-links{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.link-heading{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.link-heading span{
    font-size: 24px;
    font-weight: 600;
}

.link-tags{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.link-tags a{
    margin: 10px 0px;
    color: var(--black-color);
    font-size: 18px;
    font-weight: 500;
}

.link-tags a:hover{
    color: var(--main-color);
}

.footer-line{
    height: 3px;
    width: 80%;
    background-color: var(--main-color);
    margin: 10px 0px;
    border-radius: 10px;
}

.footer-bottom{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 70%;
    place-items: center;
    margin: 0px 0px 10px 0px;
}

.copyright a{
    color: var(--main-color);
}

@media screen and (max-width: 1250px) {
    .footer-newsletter{
        height: 250px;
    }
    .newsletter-img{
        display: none;
    }
}

@media screen and (max-width: 1050px) {
    .footer-newsletter{
        height: 350px;
    }
    .formletter{
        flex-direction: column;
    }
    .newsletter{
        padding: 0px 50px;
    }
    .newsletter input{
        width: 100%;
        margin: 10px 0px;
    }
    .footer-form{
        flex-direction: column;
    }
    .contact-form{
        align-items: center;
    }
    .heading-form{
        align-items: center;
    }
    form{
        align-items: center;
    }
    .footer-links{
        grid-template-columns: 1fr;
        place-items: flex-start;
    }
    .footer-info{
        margin: 10px 0px;
    }
    .f-links{
        margin: 10px 0px;
    }
    .map{
        border-radius: 0px 0px 20px 20px;
    }
}

@media screen and (max-width: 850px) {
    .footer-newsletter{
        height: 400px;
    }
}

@media screen and (max-width: 625px) {
    .footer-newsletter{
        width: 90%;
    }
    .footer-form{
        width: 90%;
    }
    .footer-links{
        width: 90%;
    }
    .footer-bottom{
        width: 90%;
    }
    .footer-line{
        width: 90%;
    }
}

@media screen and (max-width: 625px) {
    .newsletter span{
        font-size: 30px;
    }
}

@media screen and (max-width: 375px) {
    .newsletter span{
        font-size: 25px;
    }
}