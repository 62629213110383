.Visa {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.visa-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 50px 0px;
  width: 90%;
  padding: 10px;
}

.visa-side {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: 0px 20px 0px 0px;
}

.visa-details-tourist {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 590px;
  border-radius: 20px;
  margin: 0px 0px 30px 0px;
  background-color: var(--grey-color);
}

.visa-details-tourist a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--font-color);
  margin: 10px 0px 10px 0px;
  padding: 10px 30px;
  font-size: 24px;
  width: 90%;
  border-radius: 10px;
  color: var(--black-color);
}

.visa-details-tourist a:first-child{
  background-color: var(--main-color);
  color: var(--font-color);
}

.visa-details-tourist a:hover {
  background-color: var(--main-color);
  color: var(--font-color);
}

.visa-contact {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 400px;
  border-radius: 20px;
  background-color: var(--grey-color);
}

.visa-contact img {
  height: 100px;
}

.visa-contact span {
  text-align: center;
  font-size: 35px;
}

.visa-contact a {
  text-align: center;
  padding: 10px 30px;
  height: 50px;
  outline: none;
  border: none;
  background-color: var(--main-color);
  color: var(--font-color);
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  font-size: 24px;
  font-weight: 500;
}

.visa-contact a:hover {
  background-color: var(--green-color);
}

.visa-rapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex: 2;
  width: 100%;
  padding: 0px 0px 0px 20px;
}

.visa-rapper span1 {
  font-size: 40px;
  font-weight: 600;
  width: 100%;
}

.visa-rapper span {
  font-size: 30px;
  font-weight: 500;
  width: 100%;
}

.visa-rapper p {
  font-size: 20px;
  text-align: justify;
  width: 100%;
}

.visa-img {
  width: 100%;
}

.visa-img img {
  width: 100%;
}

.evisa {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.evisa-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 20px 0px 0px 0px;
}

.evisa-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 150px;
  width: 500px;
  background-color: var(--font-color);
  padding: 20px;
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.evisa-img {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 20%;
}

.evisa-img img {
  height: 50px;
}

.evisa-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 90%;
}

.evisa-content span {
  font-size: 20px;
  margin: 0px 0px 10px 0px;
}

.evisa-content p {
  font-size: 16px;
}

.list-img {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  width: 100%;
}

.visa-list-img {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.visa-list {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.visa-list li {
  font-size: 30px;
  list-style: none;
  margin: 10px 0px;
}

@media screen and (max-width: 1800px) {
  .visa-details-tourist a{
    font-size: 20px;
  }
}

@media screen and (max-width: 1500px) {
  .visa-container{
    flex-direction: column-reverse;
  }
  
  .visa-side{
    width: 100%;
    margin: 30px 0px;
  }
}

@media screen and (max-width: 1150px) {
  .evisa-grid{
    grid-template-columns: 1fr;
    width: 100%;
  }
  .evisa-main{
    width: 100%;
  }
  .evisa-img img{
    height: 100px;
  }
}

@media screen and (max-width: 1000px) {
  .list-img{
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 666px) {
  .evisa-img img {
    height: 50px;
  }
}

@media screen and (max-width: 470px) {
  .visa-list-img img{
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .evisa-content span {
    font-size: 16px;
  }
  .evisa-content p {
    font-size: 12px;
  }
  .evisa-img img {
    height: 40px;
  }
  .evisa-content{
    align-items: center;
  }
  .visa-list li{
    font-size: 22px;
  }
}