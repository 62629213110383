.Contact{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.branches{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    width: 100%;
    margin: 50px 0px;
}

.address{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 300px;
    width: 80%;
    gap: 20px;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid rgba(128, 128, 128, 0.472);
    background-color: var(--font-color);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.i{
    font-size: 80px;
    color: var(--green-color);
}

.icon{
    font-size: 80px;
    color: var(--main-color);
}

.address span{
    font-size: 40px;
    text-align: center;
}

.address a{
    font-size: 20px;
    color: var(--black-color);
}

.emails{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    width: 100%;
    margin: 50px 0px;
}

.email{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 300px;
    width: 80%;
    gap: 20px;
    padding: 20px;
    border-radius: 20px;
    border: 1px solid rgba(128, 128, 128, 0.472);
    background-color: var(--font-color);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.email span{
    font-size: 40px;
}

.email a{
    font-size: 20px;
    color: var(--black-color);
}

@media screen and (max-width: 1250px) {
    .branches{
        grid-template-columns: 1fr;
    }
    .address{
        margin: 20px 0px;
    }
    .emails{
        grid-template-columns: 1fr;
    }
    .email{
        margin: 20px 0px;
    }
}

@media screen and (max-width: 410px) {
    .email span{
        font-size: 30px;
    }
    .email a{
        font-size: 16px;
    }
    .address span{
        font-size: 30px;
    }
    .address a{
        font-size: 16px;
    }
}