.visa-details-spouse {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 590px;
    border-radius: 20px;
    margin: 0px 0px 30px 0px;
    background-color: var(--grey-color);
  }
  
  .visa-details-spouse a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--font-color);
    margin: 10px 0px 10px 0px;
    padding: 10px 30px;
    font-size: 24px;
    width: 90%;
    border-radius: 10px;
    color: var(--black-color);
  }
  
  .visa-details-spouse a:nth-child(4){
    background-color: var(--main-color);
    color: var(--font-color);
  }
  
  .visa-details-spouse a:hover {
    background-color: var(--main-color);
    color: var(--font-color);
  }