.About {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: antiquewhite;
  height: 450px;
  background: url(../../Assets/background.png);
}

.about-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 70%;
}

.about-content span {
  font-size: 100px;
  font-weight: 600;
}

.about-tags{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: var(--font-color);
    padding: 10px;
    border-radius: 5px;
    color: var(--main-color);
}

.about-tags a{
    color: var(--main-color);
}

.about-mc{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 50px 0px 0px 0px;
}

.about-mc span{
    font-size: 60px;
    font-weight: 600;
    width: 80%;
}

.about-mc span1{
    font-size: 35px;
    font-weight: 600;
    width: 80%;
}

.about-mc p{
    width: 80%;
    text-align: justify;
    font-size: 22px;
}

.about-mc p1{
    width: 80%;
    text-align: center;
    font-size: 22px;
    font-weight: 500;
    color: var(--main-color);
}

.about-main-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    width: 100%;
    margin: 40px 0px 40px 0px;
}

.about-span span{
    font-size: 50px;
    font-weight: 600;
}

.about-para p{
    font-size: 22px;
}

.about-photo{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0px 20px;
}

.photo{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0px 40px;
}

.photo img{
    width: 100%;
}

.photo-content{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    width: 100%;
}

.container-about{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    height: 200px;
    border-left: 1px solid rgba(128, 128, 128, 0.149);
    border-bottom: 1px solid rgba(128, 128, 128, 0.149);
}

.photo-content:last-child{
    border-right: 1px solid rgba(128, 128, 128, 0.149);
}

.container-content{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.container-about span{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
}

.container-about p{
    font-size: 30px;
}

.about-photo-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    width: 100%;
    margin: 100px 0px 50px 0px;
}

.main-about-content{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.main-about-content span{
    font-size: 50px;
    margin: 0px 0px 20px 0px;
}

.main-about-content p{
    font-size: 20px;
}

@media screen and (max-width: 1400px) {
    .about-main-content{
        grid-template-columns: 1fr;
    }
    .about-span{
        margin: 20px 0px;
    }
}

@media screen and (max-width: 1200px) {
    .about-photo-content{
        grid-template-columns: 1fr;
    }
    .main-about-content{
        margin: 30px 0px;
    }
}

@media screen and (max-width: 950px) {
    .photo-content{
        grid-template-columns: 1fr;
    }
    .container-about{
        align-items: flex-start;
        padding: 0px 20px;
    }
}

@media screen and (max-width: 650px) {
    .about-main-content{
        padding: 20px 20px;
        width: 100%;
    }
    .about-photo-content{
        padding: 20px 20px;
    }
    .about-content span{
        font-size: 70px;
    }
}

@media screen and (max-width: 525px) {
    .main-about-content p{
        width: 90%;
        font-size: 16px;
    }
    .main-about-content span{
        width: 100%;
        font-size: 35px;
    }
    .main-about-photo img{
        width: 100%;
    }
    .about-content span{
        font-size: 50px;
    }
}